<template>
  <b-container fluid class="mb-3">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Feedback dos Produtos</h4>
        </div>

        <b-card-text class="d-flex flex-column">
          <b-table
            class="lvj-table"
            :items="feedbacks"
            :fields="fields"
            :busy="isLoading"
            bordered
            striped
            hover
          >
            <template #cell(comentario)="data">
              <p class="trim-text">
                {{ data.value }}
              </p>
            </template>

            <template #cell(visualizado)="row">
              {{ row.item.visualizado ? "Sim" : "Não" }}
            </template>

            <template #cell(actions)="row">
              <b-button
                variant="primary"
                size="sm"
                class="mr-2"
                @click="openModal(row.item.id)"
              >
                <b-icon icon="pencil" />
              </b-button>

              <b-button
                :variant="row.item.visualizado ? 'success' : 'danger'"
                size="sm"
                class="mr-2"
                @click="markAsViewed(row.item.id)"
              >
                <b-icon icon="eye" />
              </b-button>
            </template>
          </b-table>
        </b-card-text>
      </b-col>
    </b-row>

    <b-modal
      title="Detalhes do Feedback"
      :visible="isModalOpen"
      size="xl"
      button-size="sm"
      @change="() => (isModalOpen = false)"
    >
      <form>
        <b-row>
          <b-col cols="3">
            <form-input
              :value="formattedFeedback.produto.codigo_produto"
              label="Código Produto:"
              icon="three-dots"
              disabled
            />
          </b-col>
          <b-col>
            <form-input
              :value="formattedFeedback.produto.descricao_completa"
              label="Descrição Produto:"
              icon="card-heading"
              disabled
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <form-input
              :value="formattedFeedback.enviado_por"
              label="Enviado por:"
              icon="person"
              disabled
            />
          </b-col>
          <b-col>
            <form-input
              :value="formattedFeedback.created_at"
              label="Enviado em:"
              icon="calendar2"
              disabled
            />
          </b-col>
        </b-row>

        <form-textarea
          :value="formattedFeedback.comentario"
          label="Comentário do Usuário:"
          icon="clipboard"
          rows="5"
          disabled
        />

        <form-textarea
          v-model="formattedFeedback.observacao"
          label="Observação:"
          icon="card-text"
          rows="5"
          :disabled="editObservation"
        />
      </form>

      <template #modal-footer>
        <b-button
          variant="success"
          class="mr-2"
          @click="() => (isModalOpen = false)"
        >
          CANCELAR
        </b-button>
        <b-button
          variant="danger"
          :disabled="editObservation"
          @click="() => makeObservation()"
        >
          ENVIAR OBSERVAÇÃO
        </b-button>
      </template>
    </b-modal>
  </b-container>
</template>

<script>
import Vue from "vue";
import FormInput from "../../components/form/FormInput.vue";
import FormTextarea from "../../components/form/FormTextarea.vue";
import http from "../../plugins/http";

export default {
  name: "ListFeedback",
  components: { FormInput, FormTextarea },
  data() {
    return {
      isLoading: true,
      isModalOpen: false,
      feedbacks: [],
      feedback: {
        id: 0,
        produtoId: 0,
        comentario: "",
        enviado_por: "",
        observacao: "",
        visualizado: false,
        created_at: "",
        updated_at: "",
        produto: {
          id: 0,
          codigo_produto: "",
          descricao: "",
          descricao_completa: "",
        },
      },
      editObservation: false,
      page: 2,
      modalMessage: "",
      showModal: false,
      fields: [
        {
          key: "comentario",
          label: "Comentário",
          sortable: true,
        },
        {
          key: "enviado_por",
          sortable: true,
          thStyle: {
            width: "236px !important",
          },
        },
        {
          key: "visualizado",
          sortable: true,
          thStyle: {
            width: "95px !important",
          },
        },
        {
          key: "actions",
          label: "Ações",
          thStyle: {
            width: "195px !important",
          },
        },
      ],
    };
  },
  computed: {
    formattedFeedback() {
      return {
        ...this.feedback,
        created_at: new Date(this.feedback.created_at).toLocaleDateString(
          "pt-BR"
        ),
      };
    },
  },
  methods: {
    async openModal(feedbackId) {
      try {
        const { data } = await http.get(`/feedback/${feedbackId}`);
        this.feedback = data;
        this.editObservation = !!data.observacao;
        this.isModalOpen = true;
      } catch (err) {
        this.$bvToast.toast(err.response.data.error, {
          title: "Erro",
          autoHideDelay: 5000,
          variant: "danger",
          solid: true,
        });
      }
    },
    async makeObservation() {
      try {
        const { data: feedback } = await http.post(
          `/feedback/${this.formattedFeedback.id}/make-observation`,
          {
            observation: this.formattedFeedback.observacao,
          }
        );
        const index = this.feedbacks.findIndex((f) => f.id === feedback.id);

        if (index !== -1) {
          Vue.set(this.feedbacks, index, {
            id: feedback.id,
            comentario: feedback.comentario,
            enviado_por: feedback.enviado_por,
            visualizado: feedback.visualizado,
          });
        }
        this.isModalOpen = false;
        this.$bvToast.toast(`Observação enviada com sucesso`, {
          title: "Sucesso",
          autoHideDelay: 5000,
          variant: "success",
          solid: true,
        });
      } catch (err) {
        this.$bvToast.toast(err.response.data.error, {
          title: "Erro",
          autoHideDelay: 5000,
          variant: "danger",
          solid: true,
        });
      }
    },
    async markAsViewed(feedbackId) {
      try {
        await http.patch(`/feedback/${feedbackId}/mark-as-viewed`);
        const index = this.feedbacks.findIndex((f) => f.id === feedbackId);

        if (index != -1) {
          Vue.set(this.feedbacks, index, {
            ...this.feedbacks[index],
            visualizado: true,
          });
        }

        this.$bvToast.toast(`Marcado como visualizado`, {
          title: "Sucesso",
          autoHideDelay: 5000,
          variant: "success",
          solid: true,
        });
      } catch (err) {
        this.$bvToast.toast(err.response.data.error, {
          title: "Erro",
          autoHideDelay: 5000,
          variant: "danger",
          solid: true,
        });
      }
    },
  },
  async created() {
    try {
      this.isLoading = true;
      const { data } = await http.get("/feedback");
      this.feedbacks = data;
    } catch (err) {
      console.log(err);
    } finally {
      this.isLoading = false;
    }
  },
};
</script>

<style lang="scss" scoped>
.lvj-table::v-deep tr th:last-child {
  width: 8%;
}

.trim-text {
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;

  @supports (-webkit-line-clamp: 2) {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}
</style>
